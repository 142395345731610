
























import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class Header extends Vue {
	private activeName = "first";
	private handleClick(tab: string, event: object) {
		console.log(tab, event);
	}
}
